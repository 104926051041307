import React from 'react';
import '../App.css';

const Listajunaeb = () => {
  const pdfFiles = [
    { title: "Minuta Mes de Abril 2025", url:"/pdfs/Minuta Abril 2025.pdf " },
    // Agrega más documentos según sea necesario
  ];

  return (
    <div id="Listajunaeb" className="junaeb-container">
      <h2>Alimentación Junaeb</h2>
      <div className="pdf-list-container">
        <ul>
          {pdfFiles.map((pdf, index) => (
            <li key={index} className="pdf-item">
              <a href={pdf.url} target="_blank" rel="noopener noreferrer">
                {pdf.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Listajunaeb;

