import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../App.css'; // Estilos personalizados para el carrusel

import imagen6 from '../img/carrusel44.jpeg';
import imagen7 from '../img/carrusel43.jpg';
import imagen47 from '../img/carrusel47.jpg';
import imagen48 from '../img/carrusel48.jpg';
import imagen49 from '../img/carrusel49.jpg';







import avisoImagen from '../img/Rafael Trujillo.jpg'; // La imagen que quieres mostrar en el aviso

const CarouselComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0); // Controlar el índice actual
  const [isFloatingMessageVisible, setIsFloatingMessageVisible] = useState(true); // Estado para mostrar/ocultar el aviso flotante  true o false

  // Función para avanzar al siguiente slide
  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 6); // 5 es el número total de imágenes
  };

  // Función para retroceder al slide anterior
  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + 6) % 6);
  };

  // Manejo de las teclas del teclado (flechas)
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        goToNext();
      } else if (e.key === 'ArrowLeft') {
        goToPrev();
      }
    };

    // Agregar el event listener para detectar las teclas
    window.addEventListener('keydown', handleKeyDown);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Abrir imagen en una nueva ventana al hacer doble clic
  const openImageInNewWindow = () => {
    window.open(avisoImagen, '_blank');
  };

  return (
    <div className="carousel-wrapper" style={{ position: 'relative' }}>
      <Carousel
        selectedItem={currentIndex} // Se pasa el índice actual
        onChange={(index) => setCurrentIndex(index)} // Actualizar el índice cuando el usuario navega
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true} // Reproducción automática siempre activa
        interval={3000} // Intervalo corto para hacer que el movimiento parezca constante
        infiniteLoop={true} // Loop infinito
        transitionTime={1500} // Tiempo de transición muy largo para suavizar el cambio entre imágenes
      >

        <div>
          <img src={imagen47} alt="Imagen 47" style={{ width: '23%', height: 'auto' }} />
        </div>
        <div>
          <img src={imagen48} alt="Imagen 48" style={{ width: '23%', height: 'auto' }} />
        </div>

        <div>
          <img src={imagen49} alt="Imagen 49" style={{ width: '23%', height: 'auto' }} />
        </div>

        
        <div>
          <img src={imagen6} alt="Imagen 6" style={{ width: '52%', height: 'auto' }} />
        </div>
        <div>
          <img src={imagen7} alt="Imagen 7" style={{ width: '52%', height: 'auto' }} />
        </div>
        
        
       
        
        
      </Carousel>

      {/* Aviso flotante */}
      {isFloatingMessageVisible && (
        <div className="floating-message" style={floatingMessageStyle}>
          <div style={floatingContentWrapperStyle}>
            <img 
              src={avisoImagen} 
              alt="Aviso" 
              onDoubleClick={openImageInNewWindow} // Abre la imagen en una nueva ventana al hacer doble clic
              style={{ 
                width: '150%',         // La imagen ocupa el 100% del contenedor
                maxWidth: '800px',     // Ajusta el ancho máximo de la imagen
                height: 'auto',        // Ajusta la altura proporcionalmente
                objectFit: 'contain'   // La imagen se ajusta dentro del espacio disponible
              }} 
            />
            <p style={{ margin: '10px 0' }}></p>
            <button onClick={() => setIsFloatingMessageVisible(false)} style={closeButtonStyle}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Estilos del aviso flotante
const floatingMessageStyle = {
  position: 'absolute',
  top: '-70px', // Ajusta la posición superior del aviso
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)', // Fondo blanco semitransparente
  padding: '10px',
  borderRadius: '10px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  zIndex: 10, // Asegura que el aviso esté por encima del carrusel
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

// Estilos internos para el contenido flotante
const floatingContentWrapperStyle = {
  maxWidth: 'fit-content', // Ajusta el ancho al contenido interno
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const closeButtonStyle = {
  marginTop: '10px',
  padding: '10px 20px',
  backgroundColor: '#ff0000', // Color del botón de cierre
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default CarouselComponent;
