import React from 'react';
import '../App.css'; // Asegúrate de crear este archivo de estilos


/*import equipopie2024 from '../img/pie2024.jpg';*/
import  uniforme from '../img/uniformenz.jpg';
import  reunioncursos from '../img/aviso.jpg'; 
import  Aviso3 from '../img/aviso-02-04-2025.jpg';
/*import  Aviso4 from '../img/Cuenta-2025.jpg';*/




const EventsComponent = () => {
  const eventsData = [
   
    /*{
      id: 4,
      date: 'Cuenta Pública 2025',
      title: '26 de Marzo 2025',
      description: 'A las 18:00 hrs.',
      imageUrl: Aviso4, // Agrega la ruta de la imagen correspondiente
    },*/

    {
      id: 3,
      date: '',
      title: '',
      description: '',
      imageUrl: Aviso3, // Agrega la ruta de la imagen correspondiente
    },

    {
      id: 2,
      date: 'Calendario Escolar 2025',
      title: '',
      description: '',
      imageUrl: reunioncursos, // Agrega la ruta de la imagen correspondiente
    }, 

      
    
    {
      id: 0,
      date: 'Uniforme Escolar',
      title: 'Escuela Nueva Zelandia 2025',
      description: '',
      imageUrl: uniforme, // Agrega la ruta de la imagen correspondiente
    },
    
    
    

    // Agrega más eventos aquí
  ];

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  return (
    <div id="events-component" className="events-component">
      <h2> Informaciones (Doble click Para agrandar imagenes)</h2>
      <div className="event-cards-container">
        {eventsData.map((event) => (
          <div key={event.id} className="event-card">
            <div className="event-date">{event.date}</div>
            <div className="event-details">
              <h3>{event.title}</h3>
              <div className="event-description"></div>
              <p>{event.description}</p>
              <img
                src={event.imageUrl}
                alt={event.title}
                onDoubleClick={() => openImageInNewTab(event.imageUrl)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventsComponent;
