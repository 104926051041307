import React, { useState } from 'react';

function ListaProfesores() {
  // Estado para mantener la lista de profesores
  const [profesores, setProfesores] = useState([
    
    {
      nombre: 'Ruth Milagros Gutierrez',
      cargo: 'Profesor(a) Jefe de: Pre-kinder',
      correo: 'rgutierrez@nzelandia.cl',
      ciclo: 1,
    },
    {
      nombre: 'Diego Garay Riffo',
      cargo: 'Técnico en Párvulos Pre-Kinder',
      correo: 'dgaray@nzelandia.cl',
      ciclo: 1,
    },
    
    {
      nombre: 'Erika órdenes Villaroel',
      cargo: 'Profesor(a) Jefe de: Kinder',
      correo: 'eordenes@nzelandia.cl',
      ciclo: 1,
    },
    {
      nombre: 'Leslie Cristi Olavarría',
      cargo: 'Técnico en Párvulos kinder',
      correo: 'lcristi@nzelandia.cl',
      ciclo: 1,
    },
    
    {
      nombre: 'Isaac Jiménez',
      cargo: 'Profesor(a) Jefe de: 1° básico',
      correo: 'ijimenez@nzelandia.cl',
      ciclo: 1,
    },
    {
      nombre: 'Alén Munay Briceño',
      cargo: 'Co-docente 1° básico',
      correo: 'abriceno@nzelandia.cl',
      ciclo: 1,
    },
    
    
    {
      nombre: 'Karina Dalia Echeverria',
      cargo: 'Profesor(a) Jefe de: 2° básico',
      correo: 'kecheverria@nzelandia.cl',
      ciclo: 1,
    },
    {
      nombre: 'Verónica Rojas',
      cargo: 'Co-docente 2° básico',
      correo: 'vrojas@nzelandia.cl',
      ciclo: 1,
    },
    {
      nombre: 'Karin Alejandra Vergara Muñoz', 
      cargo: 'Profesor(a) Jefe de: 3° básico',
      correo: 'kvergara@nzelandia.cl',
      ciclo: 1,
    },
    
    {
      nombre: 'Pamela Andrea Jorquera', 
      cargo: 'Profesor(a) Jefe de: 4° básico',
      correo: 'pjorquera@nzelandia.cl',
      ciclo: 1,
    },
       
    {
      nombre: 'Cristina Catalina Marín',
      cargo: 'Profesor(a) Jefe de: 5° básico',
      correo: 'cmarin@nzelandia.cl',
      ciclo: 2,
    },
    {
      nombre: 'Nicolás Jiménez',
      cargo: 'Profesor(a) Jefe de: 6° básico',
      correo: 'njimenez@nzelandia.cl',
      ciclo: 2,
    },

    {
      nombre: 'Claudia Andrea Guerra',
      cargo: 'Profesor(a) Jefe de: 7° básico',
      correo: 'cguerra@nzelandia.cl',
      ciclo: 2,
    },

    {
      nombre: 'Luis Alberto Morales',
      cargo: 'Profesor(a) Jefe de: 8° básico',
      correo: 'lmorales@nzelandia.cl',
      ciclo: 2,
    },  
    
    
    
    
    
    {
      nombre: 'Rafael Leonidas Trujillo',
      cargo: 'Profesor Coordinador',
      correo: 'rtrujillo@nzelandia.cl',
      ciclo: "Centro de Innovación",
    },  
    {
      nombre: 'Giovanni Mauricio Neira',
      cargo: 'Encargado Centro de Innovación',
      correo: 'gneira@nzelandia.cl',
      ciclo: "Centro de Innovación",
    }, 
    {
      nombre: 'Claudia Alejandra Aracena',
      cargo: 'Profesor(a) Coordinador(a) CRA',
      correo: 'caracena@nzelandia.cl',
      ciclo: "CRA",
    }, 
    {
      nombre: 'José Durbán',
      cargo: 'Encargado(a) CRA',
      correo: 'jdurban@nzelandia.cl',
      ciclo: "CRA",
    }, 
    {
      nombre: 'Katherina Paz Cifuentes',
      cargo: 'Coordinadora P.I.E.',
      correo: 'kcifuentes@nzelandia.cl',
      ciclo: "Equipo P.I.E.",
    },
    {
      nombre: 'Andrea Soledad Zirpel',
      cargo: 'Fonoaudióloga',
      correo: 'azirpel@nzelandia.cl',
      ciclo: "Equipo P.I.E.",
    },  

    {
      nombre: 'Camila Pérez',
      cargo: 'Fonoaudióloga',
      correo: 'cperez@nzelandia.cl',
      ciclo: "Equipo P.I.E.",
    },  

    {
      nombre: 'Yérica Guerrero',
      cargo: 'Psicóloga P.I.E.',
      correo: 'yguerrero@nzelandia.cl',
      ciclo: "Equipo P.I.E.",
    },
    {
      nombre: 'Susana Rita Sasso',
      cargo: 'Docente P.I.E.',
      correo: 'ssasso@nzelandia.cl',
      ciclo: "Equipo P.I.E.",
    },
    
    {
      nombre: 'Pamela Denisse Vidal',
      cargo: 'Docente P.I.E.',
      correo: 'pvidal@nzelandia.cl',
      ciclo: "Equipo P.I.E.",
    },
    {
      nombre: 'Maria José Astorga',
      cargo: 'Docente P.I.E.',
      correo: 'mastorga@nzelandia.cl',
      ciclo: "Equipo P.I.E.",
    },
    {
      nombre: 'Maria José Alarcón',
      cargo: 'Docente P.I.E.',
      correo: 'malarcon@nzelandia.cl',
      ciclo: "Equipo P.I.E.",
    },

// Asistentes de la Educacion 

    {
      nombre: 'Marcia Silva Donoso',
      cargo: 'Secretaría de Dirección',
      correo: 'msilva@nzelandia.cl',
      ciclo: "Administrativo",
    },

    {
      nombre: 'María Alveal Carrasco',
      cargo: 'Administrativa/Encargada PAE',
      correo: 'malveal@nzelandia.cl',
      ciclo: "Administrativo",
    },

        
    {
      nombre: 'Leslie Cristi Olavarría',
      cargo: 'Técnico en Párvulos',
      correo: 'lcristi@nzelandia.cl',
      ciclo: "Asistentes",
    },


    {
      nombre: 'Diego Garay Riffo',
      cargo: 'Técnico en Párvulos',
      correo: 'dgaray@nzelandia.cl',
      ciclo: "Asistentes",
    },
   
    {
      nombre: 'Alejandra Flores Retamales',
      cargo: 'Encargada Cra y Salud Escolar',
      correo: 'axflores@nzelandia.cl',
      ciclo: "Asistentes",
    },
    {
      nombre: 'Giovanni Neira Valencia',
      cargo: 'Encargado Centro de Innovación',
      correo: 'gneira@nzelandia.cl',
      ciclo: "Asistentes",
    },
    {
      nombre: 'Andrea Soledad Zirpel',
      cargo: 'Fonoaudióloga',
      correo: 'azirpel@nzelandia.cl',
      ciclo: "Asistentes",
    },  
    {
      nombre: 'María Eugenia Olavarría Mardones',
      cargo: 'Inspectora de Patio (Pre-Kínder a 2do)',
      correo: 'molavarria@nzelandia.cl',
      ciclo: "Inspectoria",
    },
    {
      nombre: 'Sandra Massone Arancibia',
      cargo: 'Inspectora de Patio (3ro, 4to y 5to)',
      correo: 'smassone@nzelandia.cl',
      ciclo: "Inspectoria",
    },
    {
      nombre: 'Jeannette Silva Manzo',
      cargo: 'Inspectora de Patio (6to, 7mo, 8vo)',
      correo: 'jsilva@nzelandia.cl',
      ciclo: "Inspectoria",
    },
    
    {
      nombre: 'Nancy González Villalón',
      cargo: 'Auxiliar de Servicios Menores',
      correo: 'ngonzalez@nzelandia.cl',
      ciclo: "Auxiliar",
    },
    {
      nombre: 'Berta Parra Baeza',
      cargo: 'Auxiliar de Servicios Menores',
      correo: 'bparra@nzelandia.cl',
      ciclo: "Auxiliar",
    },
    {
      nombre: 'Yessenia Toro Pérez ',
      cargo: 'Auxiliar de Servicios Menores',
      correo: 'ytoro@nzelandia.cl',
      ciclo: "Auxiliar",
    },
    {
      nombre: 'Elda Tapia Sepúlveda',
      cargo: 'Auxiliar de Servicios Menores',
      correo: 'etapia@nzelandia.cl',
      ciclo: "Auxiliar",
    },
    {
      nombre: 'Paola Cornejo Taiba',
      cargo: 'Auxiliar de Servicios Menores',
      correo: 'pcornejo@nzelandia.cl',
      ciclo: "Auxiliar",
    },
    {
      nombre: 'Antonina Ulloa Córdova',
      cargo: 'Auxiliar de Servicios Menores',
      correo: 'aulloa@nzelandia.cl',
      ciclo: "Auxiliar",
    },
    {
      nombre: 'María Flores Sanhueza',
      cargo: 'Auxiliar de Servicios Menores',
      correo: 'mflores@nzelandia.cl',
      ciclo: "Auxiliar",
    },
    {
      nombre: 'Miguel García Arroyo',
      cargo: 'Nochero',
      correo: 'mgarcia@nzelandia.cl',
      ciclo: "Asistentes",
    },
    
    
    // Agregar más profesores con diferentes ciclos
  ]);

  // Estado para mantener la lista de profesores de asignatura
  const [profesoresAsignatura, setProfesoresAsignatura] = useState([
      {
        nombre: 'Claudia Alejandra Aracena',
        cargo: 'Lenguaje',
        correo: 'caracena@nzelandia.cl',
        ciclo: 1,
      },
      
      {
        nombre: 'Claudia Andrea Guerra',
        cargo: 'Historia',
        correo: 'cguerra@nzelandia.cl',
        ciclo: 1,
      },
      {
        nombre: 'Yenny Andrea Arriagada',
        cargo: 'Ingles',
        correo: 'yarriagada@nzelandia.cl',
        ciclo: 2,
      },
      {
        nombre: 'Luis Alberto Morales',
        cargo: 'Educacion Fisica',
        correo: 'lmorales@nzelandia.cl',
        ciclo: 2,
      },
      
      {
        nombre: 'Nicolas Andre Jiménez',
        cargo: 'Ciencias Naturales',
        correo: 'njimenez@nzelandia.cl',
        ciclo: 2,
      },
      {
        nombre: 'Cristina Catalina Marín',
        cargo: 'Matematicas',
        correo: 'cmarin@nzelandia.cl',
        ciclo: 2,
      },
      {
        nombre: 'Gregory Wilfredo Arias',
        cargo: 'Música',
        correo: 'garias@nzelandia.cl',
        ciclo: 2,
      },
      {
        nombre: 'Danza',
        cargo: 'Sofía Martinez',
        correo: 'smartinez@nzelandia.cl',
        ciclo: 2,
      },
      /*{
        nombre: 'Deportes y Cra',
        cargo: 'José González',
        correo: 'jgonzalez@nzelandia.cl',
        ciclo: 2,
      },*/
  
  

    // Agregar más profesores de asignatura con diferentes ciclos
  ]);
   
 
  // Función para obtener los profesores de un ciclo específico
  const obtenerProfesoresPorCiclo = (ciclo, listaProfesores) => {
    return listaProfesores.filter((profesor) => profesor.ciclo === ciclo);
  };

  return (
    <div className="lista-profesores">
      <h2>Lista de Profesores por Ciclo</h2>
  <div className="columnas">
    {[1, 2].map((ciclo) => (
      <div key={ciclo} className="columna">
        <h3>{typeof ciclo === "string" ? ciclo : `Ciclo ${ciclo}`}</h3>
        <ul>
          {obtenerProfesoresPorCiclo(ciclo, profesores).map((profesor, index) => (
            <li key={index} className="profesor-item">
              <strong>Nombre:</strong> {profesor.nombre} <br />
              <strong></strong> {profesor.cargo} <br />
              <strong>Correo:</strong>{" "}
              <span style={{ userSelect: "none" }}>{profesor.correo}</span>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
      <h2>Lista de Profesores de Asignatura</h2>
      <div className='columna'>
      <ul>
        {profesoresAsignatura.map((profesor, index) => (
          <li key={index} className="profesor-item">
            <strong>Nombre:</strong> {profesor.nombre} <br />
            <strong>Profesor(a) de</strong> {profesor.cargo} <br />
            <strong>Correo:</strong>{" "}
            <span style={{ userSelect: "none" }}>{profesor.correo}</span>          </li>
        ))}
      </ul>
      </div>
      <h2>Centro de Innovacion, P.I.E. y CRA</h2>
  <div className="columnas">
    {[ "Centro de Innovación", "Equipo P.I.E." , "CRA"].map((ciclo) => (
      <div key={ciclo} className="columna">
        <h3>{typeof ciclo === "string" ? ciclo : `Ciclo ${ciclo}`}</h3>
        <ul>
          {obtenerProfesoresPorCiclo(ciclo, profesores).map((profesor, index) => (
            <li key={index} className="profesor-item">
              <strong>Nombre:</strong> {profesor.nombre} <br />
              <strong></strong> {profesor.cargo} <br />
              <strong>Correo:</strong>{" "}
              <span style={{ userSelect: "none" }}>{profesor.correo}</span>            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
  <h2>Asistentes de la Educación</h2>
  <div className="columnas">
    {[ "Administrativo", "Asistentes", "Inspectoria", "Auxiliar"].map((ciclo) => (
      <div key={ciclo} className="columna">
        <h3>{typeof ciclo === "string" ? ciclo : `Ciclo ${ciclo}`}</h3>
        <ul>
          {obtenerProfesoresPorCiclo(ciclo, profesores).map((profesor, index) => (
            <li key={index} className="profesor-item">
              <strong>Nombre:</strong> {profesor.nombre} <br />
              <strong></strong> {profesor.cargo} <br />
              <strong>Correo:</strong>{" "}
              <span style={{ userSelect: "none" }}>{profesor.correo}</span>            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
      <style jsx>{`
        .lista-profesores {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          background-color: #f7f7f7;
        }

        h2 {
          font-size: 24px;
          margin-top: 20px;
          color: #333;
        }

        ul {
          list-style: none;
          padding: 0;
        }

        .columnas {
          display: flex;
          justify-content: space-between;
        }

        .columna {
          flex: 1;
          padding: 10px;
          border: 1px solid #0045a8;
          border-radius: 5px;
          background-color: #fff;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .profesor-item {
          padding: 10px;
          text-align: center; /* Centra el texto dentro de los elementos */

        }
        /* Agrega una clase para la animación */
        .columna:hover {
          transform: scale(1.02); /* Aumenta el tamaño en un 5% al poner el cursor encima */
          transition: transform 0.3s ease; /* Agrega una transición suave de 0.3 segundos */
        }
        .profesor-item:hover {
          transform: scale(1.05); /* Aumenta el tamaño en un 5% al poner el cursor encima */
          transition: transform 0.3s ease; /* Agrega una transición suave de 0.3 segundos */
        }
        @media (max-width: 768px) {
          .lista-profesores {
            padding: 10px;
          }

          h2 {
            font-size: 20px;
          }

          .columnas {
            flex-direction: column; /* Cambia la dirección a columna en dispositivos móviles */
          }

          .columna {
            margin-top: 20px; /* Agrega espacio entre columnas en dispositivos móviles */
          }
        }
        
      `}</style>
    </div>
  );
}

export default ListaProfesores;
