import React, { useState } from "react";
import nelson from "../img/4.jpeg";
import aldo from "../img/1.jpg";
import paulina from "../img/5.jpeg";
import viviana from "../img/2.jpg";
import Katherina from "../img/3.jpg";
import "../App.css";

function QuienesSomos() {
  const [selectedCard, setSelectedCard] = useState(null);
  const [zoomedImage, setZoomedImage] = useState(null); // Estado para controlar la imagen ampliada

  const directiva = [
    {
      nombre: "Nelson Bobadilla Rodríguez",
      cargo: "Director",
      correo: "Correo: nbobadilla@nzelandia.cl",
      imagen: nelson,
    },
    {
      nombre: "Aldo Alberto Bustamante",
      cargo: "Inspector General",
      correo: "Correo: abustamantes@nzelandia.cl",
      imagen: aldo,
    },
    {
      nombre: "Paulina Andrea Canelo Flores",
      cargo: "UTP",
      correo: "Correo: pcanelo@nzelandia.cl",
      imagen: paulina,
    },

     {
      nombre: "Viviana Díaz Casanova",
      cargo: "Convivencia Escolar",
      correo: "Correo: vdiaz@nzelandia.cl",
      imagen: viviana,
    },
    {
      nombre: "Katherina Paz Cifuentes",
      cargo: "Coordinadora P.I.E.",
      correo: "Correo: kcifuentes@nzelandia.cl",
      imagen: Katherina,
    }, 
  ];

  const handleCardClick = (correo) => {
    setSelectedCard(correo);
  };

  const closeCard = () => {
    setSelectedCard(null);
  };

  const handleDoubleClick = (image) => {
    setZoomedImage(image); // Establece la imagen ampliada
  };

  const closeZoom = () => {
    setZoomedImage(null); // Cierra la imagen ampliada
  };

  return (
    <section id="quienes-somos">
      <div className="content">
        <h2>Quiénes Somos</h2>
        <p>
          Somos una escuela pública, de educación Parvularia y Básica, que fue
          fundada el año 1966 en el marco de la reforma educativa de 1965. En
          la actualidad depende la administración comunal de la Municipalidad
          de Independencia...
        </p>
        <div className="image-container">
          <section>
            <div className="content">
              <h2>Equipo de Gestión</h2>
              <p>¡Conoce a nuestro Equipo directivo!</p>
              <div className="alumnos-container">
                {directiva.map((directivo, index) => (
                  <div
                    key={index}
                    className={`alumno-card ${
                      selectedCard === directivo.correo ? "active" : ""
                    }`}
                    onClick={() => handleCardClick(directivo.correo)}
                  >
                    <img
                      src={directivo.imagen}
                      alt={`${directivo.nombre} - ${directivo.cargo}`}
                      onDoubleClick={() => handleDoubleClick(directivo.imagen)}
                    />
                    <h3>{directivo.nombre}</h3>
                    <p>{directivo.cargo}</p>
                    <span style={{ userSelect: "none" }}>
                      {directivo.correo}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
        {zoomedImage && (
          <div className="zoomed-image-container" onClick={closeZoom}>
            <img
              src={zoomedImage}
              alt="Imagen ampliada"
              className="zoomed-image"
            />
          </div>
        )}
      </div>
      <style jsx>{`
        .alumnos-container {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 20px;
        }

        .alumno-card {
          border: 1px solid #ccc;
          border-radius: 10px;
          padding: 20px;
          text-align: center;
          background-color: #f7f7f7;
          transition: transform 0.2s, box-shadow 0.2s;
          width: 220px;
          cursor: pointer;
        }

        .alumno-card:hover {
          transform: translateY(-15px);
          box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
        }

        .alumno-card img {
          width: 80px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
          transition: transform 0.2s;
        }

        .alumno-card:hover img {
          transform: scale(1.1);
        }

        .zoomed-image-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: zoom-out;
        }

        .zoomed-image {
          max-width: 90%;
          max-height: 90%;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        }
      `}</style>
    </section>
  );
}

export default QuienesSomos;
