import React, { useState } from 'react';
import '../App.css';
import facebook from '../img/facebookcirclegrande.png';
import instagram from '../img/instagramcirclegrande.png';
import twitter from '../img/twittercirclegrande.png';
import iconomapa from '../img/iconomapa.png';
import youtubeicono from '../img/youtubeicono.png';

function Contacto() {
  const direccion = "Gamero 2722";
  const telefono = "+56227103043";
  const comuna = " Independencia ";

  const [iconHovered, setIconHovered] = useState(null);

  const handleIconClick = (icon) => {
    if (iconHovered === icon) {
      setIconHovered(null);
    } else {
      setIconHovered(icon);
    }
  };

  return (
    <section id="contacto">
      <div className="content">
        <h2>Contacto</h2>
        <p>Para contactarnos, puedes hacerlo de las siguientes formas:</p>
        <ul>
          <li><h2>Dirección: {direccion}, {comuna}</h2></li>
          <li><h2>Teléfono: {telefono}</h2></li>
          <li><h2>Correo electrónico: escuela@nzelandia.cl</h2></li>
        </ul>

        {/* Botón de enlace a Google Maps */}
        <a
          href="https://maps.app.goo.gl/d2wyMpzZ9RCua6TD8" 
          className="como-llegar-button" 
          target="_blank" 
          rel="noopener noreferrer">
          Cómo llegar (Mapa)
          <img src={iconomapa} alt="Mapa" className="map-icon" />
        </a>

        {/* Mapa incrustado de Google Maps */}

        {/*<div style={{ marginTop: '20px' }}>
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1376.414367105761!2d-70.67253660743205!3d-33.4218996416447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5fc29de65d7%3A0x3ca23f381c757a05!2sEscuela%20B%C3%A1sica%20Nueva%20Zelandia!5e1!3m2!1ses!2scl!4v1728910055776!5m2!1ses!2scl"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Mapa de Escuela Nueva Zelandia"
          ></iframe>
        </div> */}

        <div style={{ marginTop: '20px', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1376.414367105761!2d-70.67253660743205!3d-33.4218996416447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5fc29de65d7%3A0x3ca23f381c757a05!2sEscuela%20B%C3%A1sica%20Nueva%20Zelandia!5e1!3m2!1ses!2scl!4v1728910055776!5m2!1ses!2scl"
          style={{
          border: 0,
          width: '100%',
          height: '100%',
          aspectRatio: '16/9', // Relación de aspecto para mantener proporciones
          }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Mapa de Escuela Nueva Zelandia"
           ></iframe>
        </div>





        {/* Redes Sociales */}
        <div className="redes-sociales-container">
          <div className="redes-sociales">
            <div className="icon-container">
              <a
                href="https://www.instagram.com/nuevazelandiaescuela"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleIconClick('instagram')}
              >
                <img
                  src={instagram}
                  alt="Logo de Instagram"
                  className={`icon ${iconHovered === 'instagram' ? 'hovered' : ''}`}
                />
              </a>
            </div>

            <div className="icon-container">
              <a
                href="https://twitter.com/zelandia_nueva?lang=bg/"
                target="_blank"
                style={{ width: '40px' }}
                rel="noopener noreferrer"
                onClick={() => handleIconClick('twitter')}
              >
                <img
                  src={twitter}
                  alt="Logo de Twitter"
                  className={`icon ${iconHovered === 'twitter' ? 'hovered' : ''}`}
                />
              </a>
            </div>

            <div className="icon-container">
              <a
                href="https://www.facebook.com/EscuelaNuevaZelancia"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleIconClick('facebook')}
              >
                <img
                  src={facebook}
                  alt="Logo de Facebook"
                  className={`icon ${iconHovered === 'facebook' ? 'hovered' : ''}`}
                />
              </a>
            </div>

            <div className="icon-container">
              <a
                href="https://www.youtube.com/channel/UCv9kvVQXl1zXASkSQfra55w"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleIconClick('youtube')}
              >
                <img
                  src={youtubeicono}
                  alt="Logo de Youtube"
                  className={`icon ${iconHovered === 'youtube' ? 'hovered' : ''}`}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contacto;
